var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-tabs-custom"},[_c('ul',{ref:"tabs",staticClass:"nav nav-tabs"},_vm._l((_vm.libraries),function(lib,index){return _c('li',{key:index,class:{active: _vm.currentTab == lib.name},attrs:{"role":"presentation","data-testid":"tab"}},[_c('a',{attrs:{"href":'#' + lib.prefix,"data-toggle":"tab"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.currentTab = lib.name}}},[_vm._v(_vm._s(lib.name))])])}),0),_c('div',{staticClass:"input-group input-group-sm",staticStyle:{"margin":"0.8em 0"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"query",staticClass:"form-control",attrs:{"type":"search","placeholder":_vm.$t('search'),"data-testid":"search"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}}),_c('div',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-default",attrs:{"data-testid":"reset"},on:{"click":function($event){_vm.query = '';
          _vm.$refs.query.focus();}}},[_c('span',{staticClass:"fa fa-remove"})])])]),_c('div',{staticClass:"tab-content"},_vm._l((_vm.libraries),function(lib,index){return _c('div',{key:index,class:['tab-pane', {active: _vm.currentTab == lib.name}],attrs:{"id":lib.prefix,"data-testid":"tab-content"}},[_c('div',{staticClass:"icon-info"},[_c('ul',{key:_vm.cid + index,ref:"icons",refInFor:true,staticClass:"clearfix"},_vm._l((_vm.loadedIcons[lib.prefix]),function(icon,index){return _c('li',{key:index,class:{active: _vm.isSelected(lib.prefix, icon.name)},attrs:{"title":icon.name +
                (icon.aliases.length
                  ? ` (${_vm.$tc('alias', 2).toLowerCase()}: ${icon.aliases.join(
                      ', '
                    )})`
                  : ''),"data-testid":"icon"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.select(lib.prefix, icon.name)}}},[_c('span',{class:`icon ${lib.prefix} ${lib.prefix}-${icon.name}`}),_c('span',{staticClass:"icon-name",attrs:{"data-testid":"icon-name"}},[_vm._v(_vm._s(icon.name))])])}),0)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }